@import "src/colors.scss";

$size: 50px;

#tradingview-widget-container {
  position: relative;
}

.invisible-chart-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: transparent;
  z-index: 9;
  display: none;
}

.notLoaded {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $newModalBackground;
    z-index: 1;
  }
}

.invisible-chart-overlay.expanded {
  display: block;
}

.tr-light-theme {
  .tr-chart-expand-icon {
    path {
      fill: $mainFontColorDark !important;
    }
  }
}

.tr-dark-theme {
  .notLoaded {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $mainColor;
      z-index: 1;
    }
  }

  .tr-chart-expand-icon {
    path {
      fill: $mainFontdark !important;
    }
  }
}

.tr-light-theme {
  $spinner: $mainButtonColor;
  $background: $newModalBackground;

  .spinner {
    position: absolute;
    z-index: 10;
    top: calc(50% - #{$size / 2});
    left: calc(50% - #{$size / 2});
    width: $size;
    height: $size;
    animation: spinner 0.6s linear infinite;

    span {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    .spinner-inner-1 {
      background: linear-gradient(
        to right,
        rgba($spinner, 0) 0%,
        rgba($spinner, 0) 50%,
        $spinner 51%
      );
    }

    .spinner-inner-2 {
      background: linear-gradient(
        to top,
        rgba($background, 0) 0%,
        $background 100%
      );
    }

    .spinner-inner-3 {
      $offset: $size * 0.1;
      top: $offset;
      left: $offset;
      width: $size - ($offset * 2);
      height: $size - ($offset * 2);
      background: $background;
    }
  }
}
.tr-dark-theme {
  $spinner: $mainButtonColorDark;
  $background: $mainColor;

  .spinner {
    position: absolute;
    z-index: 10;
    top: calc(50% - #{$size / 2});
    left: calc(50% - #{$size / 2});
    width: $size;
    height: $size;
    animation: spinner 0.6s linear infinite;

    span {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    .spinner-inner-1 {
      background: linear-gradient(
        to right,
        rgba($spinner, 0) 0%,
        rgba($spinner, 0) 50%,
        $spinner 51%
      );
    }

    .spinner-inner-2 {
      background: linear-gradient(
        to top,
        rgba($background, 0) 0%,
        $background 100%
      );
    }

    .spinner-inner-3 {
      $offset: $size * 0.1;
      top: $offset;
      left: $offset;
      width: $size - ($offset * 2);
      height: $size - ($offset * 2);
      background: $background;
    }
  }
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
