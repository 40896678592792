#custom-toolbar {
  display: flex;
  justify-content: space-between;
  gap: 3px;
  margin: 5px;
  width: 100%;
  margin-top: 10px;
}
.custom-toolbar-interval-switch {
  border-radius: 24px;
  padding: 1px 3px;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1px;
}
.interval-btn {
  outline: none;
  border: none;
  font-size: 10px;
  border-radius: 8px;
  padding: 3px 6px;
  flex: 1;
}

.custom-toolbar-chart-type-switch {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
}
#icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 14px;
  width: 14px;
}

.tr-light-theme {
  .custom-toolbar-interval-switch {
    background-color: #eff1f5;
    color: #000;
  }
  .interval-btn {
    background-color: #eff1f5;
    color: #aaaaaa;
  }
  .interval-btn.active {
    font-weight: 700;
    background-color: #fff;
    color: #000a26;
  }
  .custom-toolbar-chart-type-switch {
    background-color: #eff1f5;
    color: #000a26;
  }
}

.tr-dark-theme {
  .custom-toolbar-interval-switch {
    background-color: #1d1d1e;
    color: #8a8c91;
  }
  .interval-btn {
    background-color: #1d1d1e;
    color: #aaaaaa;
  }
  .interval-btn.active {
    font-weight: 700;
    color: #fff;
    background-color: #373737;
  }
  .custom-toolbar-chart-type-switch {
    background-color: #1d1d1e;
    color: #fff;
  }
}
