.debug {
  overflow-y: auto;
  position: fixed;
  left: 0;
  top: 0;
  background-color: gray;
  .price-data {
    min-width: 50px;
    font-size: 12px;
    font-weight: 400;
    margin: 5px;
    padding: 1px;
    cursor: pointer;
  }
  .price-data:hover {
    background-color: #f0f0f0;
  }
  .price-data .selected {
    color: blue;
  }
}
