@import "/src/colors.scss";

.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;

  #tradingview-widget-container {
    height: 100%;
    width: 100%;
    flex: 1;
  }
}

.main-container.debug {
  position: relative;
}

.tr-light-theme {
  background-color: $newModalBackground;
}

.tr-dark-theme {
  background-color: $mainColor;
}


.tr-scrollbar::-webkit-scrollbar {
  width: 5px !important;
  height: 0px;
}

/* Track */
.tr-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e4e4e482 !important;
  border-radius: 10px !important;
}

/* Handle */
.tr-scrollbar::-webkit-scrollbar-thumb {
  background: #c0c0c0 !important;
  border-radius: 10px !important;
}

/* Handle on hover */
.tr-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #888 !important;
}

.tr-scrollbar {

  padding-right: 3px;
  scroll-padding: 0px;
  width: 100%;
}

.tr-scrollbar.tr-scrollbar-extrawidth {
  width: calc(100% + 6px);
}



/* Track */
.tr-dark-theme .tr-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(0, 0, 0) !important;

}

/* Handle */
.tr-dark-theme .tr-scrollbar::-webkit-scrollbar-thumb {
  background: #555 !important;
  border-radius: 10px !important;
}

/* Handle on hover */
.tr-dark-theme .tr-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #888 !important;
}

.tr-scrollbar.tr-firefox {
  scrollbar-width: thin
}