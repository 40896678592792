@import "src/colors.scss";

.indicator-dropdown {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    position: relative;


}

.indicator-dropdown-content {
    position: absolute;
    top: 35px;
    right: 10px;
    width: 90%;
    z-index: 100;
    border-radius: 10px;
    padding: 5px;
}

.indicator-dropdown-body {
    overflow: auto;
    height: 200px;
}

.indicator-dropdown-trigger {
    width: 20px;
    height: 20px;
    padding: 2px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
}

.indicator-dropdown-item {
    padding: 2px 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.indicator-dropdown-search-bar {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    height: 30px;
}

.indicator-dropdown-search-bar-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

.indicator-dropdown-search-bar input {
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
}

.indicator-close {
    color: red;
}

.indicator-close:hover {
    cursor: pointer;
}


.tr-light-theme {
    .indicator-dropdown-item-start.selected {
        color: $bluefont;
    }

    .indicator-dropdown-search-bar {
        border-bottom-color: $borderGrey;

    }

    .indicator-dropdown-trigger {
        background-color: $borderGrey;
    }

    .indicator-dropdown-content {
        background-color: #fff;
        box-shadow: 0 0 10px 0 $borderGrey;
    }

}

.tr-dark-theme {
    .indicator-dropdown {
        color: $mainColorDark;
    }

    .indicator-dropdown-item-start.selected {
        color: $mainButtonColorDark;
    }

    .indicator-dropdown-search-bar {
        border-bottom-color: $iconfontgreylight;

    }

    .indicator-dropdown-trigger {
        background-color: #1D1D1E;
        color: #fff;
    }

    .indicator-dropdown-search-bar input {
        background-color: #1D1D1E;
        color: #fff;
    }

    .indicator-dropdown-content {
        background-color: #1D1D1E;
        border: 1px solid $iconfontgreylight;
    }
}