$mainColor: #000000;
$mainBackground: white;
$mainBorder: #e3e2e2;
$mainButtonColor: #002ce0;
$mainButtonShadow: #f5f7fe;
$mainShadowColor: #007494;
$mwItemColor: #f7f7f7;
$iconsColor: #131a2a;
$badgeColor: #000000;
$connected: #5cd098;
$appBackground: white;
$red: #d14343;
$redo: #d1434333;
$redo4: #d1434366;
$green: #14b8a6;
$greno4: #14b8a666;
$greeno: #14b8a633;
$blueo: #ccd7fa;
$bluefont: #003be5;
$orange: #ffb039;
$swich: #5cd09888;
$lightblue: #e1eafc;
$lightblue1: #f5f7fe;
$light3: #e1eafc;
$borderGrey: #eff1f5;
$appBackgroundMain: #eff1f5;
$placeholderGray: #858585;
$fontWhiteOpacity25: #ffffffbf;
$mainFontColorDark: #000a26;
$skyblue: #003be526;
$orderSummaryBorderLight: #e8e8e8;
$tablerowbackgroundlight_th: #f5f7fe;
$tileSelectedLight: #f1f4fc;
$badgeColorLight: #e0e9fb;
$iconfontgreylight: #787878;
$borderGreyLight: #d8d8d8;
$scrollIndicatorLight: #ccd8fa;
$spacerGray: #d9d9d9;
$fontLight: #707070;
$newModalBackground: #f7f7f9;

$mainColorDark: #dcdddf;
$backgroundBtnDark: #222222;
$mainBackgroundDark: #0a0a0a;
$mainBorderDark: #1c1c1c;
$mainButtonColorDark: #03e4ba;
$mainButtonShadowDark: #03e4ba;
$mainShadowColorDark: #007494;
$mwItemColorDark: #1c1c1c;
$iconsColorDark: #aeb8c4;
$badgeColorDark: maroon;
$connectedDark: #5cd098;
$appBackgroundDark: black;
$appBackgroundMainDarkNew: #232323;
$redDark: #d13444;
$greenDark: #5cd098;
$orangeDark: #ffb039;
$swichDark: #5cd09888;
$lightblueDark: #03325a;
$lightblue1Dark: #0a0a0a;
$light3Dark: #e1eafc;
$borderGreyDark: #eff1f5;
$appBackgroundMainDark: #eff1f5;
$scrollbarDark: #505050;
$mainFontdark: #acacac;
$buyBGDark: #14b8a666;
$successAndBuyDark: #14b8a6;
$sellBGDark: #d1434366;
$orderSummaryBackground: #131313;
$fontWhiteDarkMode: #d8d8d8;
$orgrayfont: #aaaaaa;
$orgrayinputoneclick: #8a8c91;
$rowexpandedbackgroundDark: #053631;
$rowactionsfolderbackgroundred: #e554544d;
$tablerowbackgrounddark_th: #031614;
$tileSelectedDark: #1d2524;
$inputTableBackgroundDark: #131313;
$iconFontGreyBrightDark: #d0d7dc;
$summaryCollapsedBGDark: #0b322d;
$cellBackgroundDark: #353535;
$scollInicatorDark: #195b53;
$newModalSwitchDarkBG: #373737;
$newModalBGDark: #050000;
$newModalPrimaryGreen: #0e8e80;
